.ltr{
    direction: ltr;
}
button {
    cursor: pointer;
}
.feather {
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.is-active .feather {
    fill: currentColor;
}
.is-small .feather {
    width: 18px;
    height: 18px;
}


.icon-text {
    align-items: center !important;
}

.tag {
    border: 2px solid #f9f9f9;
}

a.tag:hover {
    background-color: #f7f7f7;
    text-decoration: none !important;
}

.card-header {
    border-bottom: 2px solid #000000;
}
.footer{
    border-top: 2px solid #f7f7f7;
}
.palette-colors {
    direction: ltr;
    border-radius: 10px;
    overflow: hidden;
}
.palette-colors li, .palette-colors input{
    position: relative;
}

.palette-colors li:nth-child(1), .palette-colors input:nth-child(1){
    height: 5.5em;
}
.palette-colors li:nth-child(2), .palette-colors input:nth-child(2){
    height: 4.5em;
}
.palette-colors li:nth-child(3), .palette-colors input:nth-child(3){
    height: 3.5em;
}
.palette-colors li:nth-child(4), .palette-colors input:nth-child(4){
    height: 2.5em;
}
.palette-colors.more li{
    height: 5.5em !important;
}
.palette-colors a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
}
.palette-colors li:hover>span {
    display: block;
    animation: fadeIn;
    animation-duration: 0.3s;
}
.palette-colors li>span {
    font-size: 0.875em;
    text-align: center;
    display: none;
    position: absolute;
    left: 0;
    height: 1.5em;
    line-height: 1.5em;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    text-transform: uppercase;
    animation: fadeOut;
    animation-duration: 0.3s;
    border-radius: 0 6px 0 0;
    bottom: 0;
    padding: 3px 6px;
    cursor: pointer;
}

.palette-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.palette-detail > small {
    font-family: dana-fanum;
}
.container {
    max-width: 96%;
}


.sp-colorize-container{
    height: 25%;
    position: relative !important;
    display: block !important;
}
.sp-colorize{
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: transparent !important;
}
.palette-colors .tab{
    height: 25% !important;
}