// Variables
@import 'variables';

// bulma
@import '~bulma/bulma';

// Import BulmaTagsInput main Sass File
@import '@creativebulma/bulma-tagsinput/src/sass/index';

// css
@import '~animate.css';

// Color Picker
@import 'spectrum-colorpicker2/src/spectrum.css';

// toastify
@import 'toastify-js/src/toastify.css';

// css
@import '../css/app.css';

