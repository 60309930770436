$rtl: true;
$family-primary: dana;

// elements/tag
$tag-background-color: white;

//components/card/
$card-shadow: none;
$card-content-padding: 1.5rem 0;
$card-header-shadow: none;
$card-header-padding: 0.75rem 0;

// components/menu/
$menu-list-link-padding: 0.5em 0.75em;
$menu-item-radius: 4px;

// elements/icon/
$icon-text-spacing: 0.5em;

// layout/footer/
$footer-padding: 2.5rem 0;
$footer-background-color: #ffffff;

// input/form
$input-shadow: none;
$input-border-color: #f7f7f7;

// elements/notification
$notification-padding: 1.25rem 1.5rem;
$notification-padding-ltr: $notification-padding;
$notification-padding-rtl: $notification-padding;


//
$control-border-width:2px;
$button-border-color: #dbdbdb;
$button-active-border-color: #dbdbdb;

// elements/buttons
$button-border-width: 1px;